import { useState } from 'react';
import Web3Modal from "web3modal";
// @ts-ignore
import WalletConnectProvider from "@walletconnect/web3-provider";
import {ethers} from "ethers";
import ContractABI from "../abis/MechanizedAbstractions.json";

export default function useWeb3() {
    const [provider, setProvider] = useState(null);
    const [signer, setSigner] = useState(null);
    const [address, setAddress] = useState(null);
    const [contract, setContract] = useState(null);
    const [isConnected, setIsConnected] = useState(false);

    const providerOptions = {
        walletconnect: {
            package: WalletConnectProvider,
            options: {
                // Mikko's test key - don't copy as your mileage may vary
                infuraId: "6463e5ec2e63424e8f581310917367ad",
            }
        }
    };
    const web3Modal = new Web3Modal({
        cacheProvider: true,
        providerOptions,
        disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
    });

    async function initiateWeb3(proxy) {
        const _provider = new ethers.providers.Web3Provider(proxy);
        const _signer = await _provider.getSigner();
        const _address = await _signer.getAddress();
        const _contract = new ethers.Contract(ContractABI.address, ContractABI.abi, _provider);

         // Dev commands
        //let _localProvider = new ethers.providers.JsonRpcProvider("http://127.0.0.1:8545/");
        //let _localSigner = await _localProvider.getSigner();

        // turn on sales
        //await _contract.connect(_localSigner).flipSaleState()
        //await _contract.connect(_localSigner).flipPreSaleState()

        // set uniswap trigger to 0 link
        //await _contract.connect(_localSigner).setMinRequiredLinkBalance(0);

        /*await _localSigner.sendTransaction({ 
            to: "0x9Cc3d5291D1dc1C2E3c49f002CC31eeDBB687c0f",
            value: ethers.utils.parseEther("10.0") });

        await _localSigner.sendTransaction({ 
            to: "0xD5c502B0BB62A9F3EBf5032A9C764919E2321D21",
            value: ethers.utils.parseEther("10.0") });

        await _localSigner.sendTransaction({ 
            to: "0x8301b459d30902192e0a4e9929B10A38c35269cC",
            value: ethers.utils.parseEther("10.0") });
            
        await _localSigner.sendTransaction({ 
            to: "0x6C202bd88BFa40a13C43B61E9128B0912F2421dD",
            value: ethers.utils.parseEther("10.0") });
            
        await _localSigner.sendTransaction({ 
            to: "0xAd693404918c5e9C3D3CB0Aa26e18CC2E64b9b4F",
            value: ethers.utils.parseEther("10.0") });*/


        setProvider(_provider);
        setSigner(_signer);
        setAddress(_address);
        setContract(_contract);
        setIsConnected(true)
    }

    async function tryAutoConnect() {
        let { cachedProvider } = web3Modal;
        let cachedFromStorage = JSON.parse(localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER"));

        if(cachedProvider && cachedProvider !== "") {
            initiateWeb3(await web3Modal.connectTo(cachedProvider));
        } else if (cachedFromStorage && cachedFromStorage != "") {
            initiateWeb3(await web3Modal.connectTo(cachedFromStorage));
        } else {
            return;
        }
    }

    async function connect() {
        initiateWeb3(await web3Modal.connect());
    }

    return [
        provider,
        signer,
        address,
        contract,
        isConnected,
        tryAutoConnect,
        connect
    ]
}