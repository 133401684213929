import React, { useState, useEffect } from "react";
import "./index.scss";
import { Link, useHistory, useLocation } from "react-router-dom";

import { Layout, Button, Drawer } from "antd";

import useWeb3 from "hooks/useWeb3";

// icons
import { MenuOutlined } from "@ant-design/icons";

const { Header } = Layout;

const HeaderPage = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);

  const handleToggleMenu = () => {
    setOpen(!open);
  };

  const handlePreMint = () => {
    setOpen(false);
    history.push("/pre-mint");
  };

  const handleGallery = () => {
    setOpen(false);
    history.push("/gallery");
  };

  /** WEB 3 */
  const [
    provider,
    signer,
    address,
    contract,
    isConnected,
    tryAutoConnect,
    connect,
  ] = useWeb3();

  useEffect(() => {
    tryAutoConnect();
  }, []);

    const showTruncatedAddress = (addr) => {
        if (addr)
            return addr.slice(0, 6) + "..." + addr.slice(-5)
        else
            return ""
    }

  return (
    <Header className="header" style={{ zIndex: 1, width: "100%" }}>
      <Link to="/" className="logo">
        mechanized <span>abstractions</span>{" "}
        {pathname === "/pre-mint" && (
          <span className="textYellow">pre-mint</span>
        )}
        {pathname === "/gallery" && <span className="textBlue">gallery</span>}
        {pathname === "/pfp-studio" && (
          <span className="textBlue">pfp studio</span>
        )}
        {pathname === "/mint" && <span className="textBlue">mint</span>}
      </Link>

      <Button type="text" className="btnMenuMobile" onClick={handleToggleMenu}>
        <MenuOutlined />
      </Button>

      <div className="menus">
        <a
          onClick={handleGallery}
          rel="noreferrer"
        >
          GALLERY
        </a>
        <a
          href="https://discord.gg/XAmJCARJSj"
          target="_blank"
          rel="noreferrer"
        >
          DISCORD
        </a>
        <a
          href="https://twitter.com/abstractionsNFT"
          target="_blank"
          rel="noreferrer"
        >
          TWITTER
        </a>
        {pathname === "/pfp-studio" && <Link to="/gallery">MY GALLERY</Link>}
        {pathname !== "/" && (
          <Link to="/pre-mint">
            <Button
              className={` ${
                pathname === "/gallery" || pathname === "/pfp-studio"
                  ? "btnWalletId"
                  : pathname === "/pre-mint"
                  ? "btnConnect"
                  : "btnPreMint"
              }`}
            >
              {pathname === "/gallery" || pathname === "/pfp-studio"
                ? showTruncatedAddress(address)
                : pathname === "/pre-mint"
                ? isConnected ? showTruncatedAddress(address) : ("Connect Wallet")
                : "Pre-mint"}

              {/* {pathname === "/gallery"
                ? isConnected
                  ? address
                  : ""
                : "Pre-mint"} */}
            </Button>
          </Link>
        )}
      </div>

      <Drawer
        title="Menu"
        placement="right"
        onClose={handleToggleMenu}
        visible={open}
      >
        <div className="menusMobile">
          <div>
            <a
              href="https://discord.gg/PeYSZ6WXBx"
              target="_blank"
              rel="noreferrer"
            >
              DISCORD
            </a>
          </div>
          <div>
            <a
              href="https://twitter.com/abstractionsNFT"
              target="_blank"
              rel="noreferrer"
            >
              TWITTER
            </a>
          </div>
          {pathname !== "/" && (
            <div>
              <Button
                className={` ${
                  pathname === "/gallery" ? "btnWalletId" : "btnPreMint"
                }`}
                onClick={handlePreMint}
              >
                {pathname === "/gallery" ? "0x139203292039b302" : "Pre-mint"}
              </Button>
            </div>
          )}
        </div>
      </Drawer>
    </Header>
  );
};

export default HeaderPage;
