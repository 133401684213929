import React from "react";

const HomePage = React.lazy(() => import("pages/home"));
const PreMintPage = React.lazy(() => import("pages/pre-mint"));
const MintPage = React.lazy(() => import("pages/mint"));
const Gallery = React.lazy(() => import("pages/gallery"));
const PfpStudio = React.lazy(() => import("pages/pfp-studio"));
const PremintActive = React.lazy(() => import("pages/pre-mint-active"));
const GiveAway = React.lazy(() => import("pages/giveaway"));
const Admin = React.lazy(() => import("pages/admin"));
const Live = React.lazy(() => import("pages/live"))

export const routes = [
   {
     path: "/gallery",
     component: Gallery,
     exact: true,
   },
   {
     path: "/pfp-studio",
     component: PfpStudio,
     exact: true,
   },
   {
     path: "/pre-mint",
     component: PreMintPage,
     exact: true,
   },
   {
     path: "/mint",
     component: MintPage,
     exact: true,
   },
   {
     path: "/pre-mint-active",
     component: PremintActive,
     exact: true,
   },
   {
     path: "/giveaway",
     component: GiveAway,
     exact: true,
   },
   {
     path: "/admin",
     component: Admin,
     exact: true,
   },
   {
     path: "/live",
     component: Live,
     exact: true,
   },
  {
    path: "/",
    component: HomePage,
    exact: true,
  },
];
