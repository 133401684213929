import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "assets/fonts/stylesheet.scss";
import "antd/dist/antd.css";
import "./index.scss";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";

import { routes } from "./routes";
import { Layout, Spin } from "antd";

import HeaderPage from "components/layout/header";
import { MoralisProvider } from "react-moralis";

ReactDOM.render(
  <React.StrictMode>
      <MoralisProvider appId="0qIpQ2cJgItTyv8lkWTLBDMuZTnDkXfAzaUkfqH5" serverUrl="https://g6xrnglbrn99.usemoralis.com:2053/server">
        <Suspense
            fallback={
                <div className="loadingPage">
                <Spin size="large" />
                </div>
            }
            >
            <BrowserRouter>
                <Layout>
                <HeaderPage />
                <Layout.Content className="wrapperContent">
                    <Switch>
                    {routes.map((route, i) => (
                        <Route
                        key={i}
                        path={route.path}
                        render={(props) => (
                            // pass the sub-routes down to keep nesting
                            <route.component {...props} routes={route.routes} />
                        )}
                        />
                    ))}
                    </Switch>
                </Layout.Content>
                </Layout>
            </BrowserRouter>
            </Suspense>
      </MoralisProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
